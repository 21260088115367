/** fonts **/

/** breakpoints **/

$tablet: 768px;
$lg-breakpoint: 992px;
$laptop: 1024px;
$desktop: 1500px;
$large-desktop: 1700px;


/** colors **/


$darkblue: #090e2e;
$mediumblue:#292d4a;
$mediumblue2: #1872a9;
$lightblue: #00bdf9;
$lighterblue: #71ddff;
$midblue: #0485b5;
$gray: #565656;
$bluegray: #8f97ac;
$darkgray: #535c71;
$darkbluegray: #535c71;
$lightblue2: #1e7f98;
$lightblue3: #51c0dc;
$lightgray: #f6f6f6;
$darkerblue: #1d2034;