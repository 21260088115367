@import 'variables.scss';
@import 'mixins.scss';

@import 'layout.scss';
@import 'filters.scss';
@import 'disclaimer.scss';
@import 'primary-menu.scss';
@import 'salesforce_newsletter_subscribe.scss';
@import 'research-collaborations-grid.scss';
@import 'modal.scss';
@import 'buttons.scss';
@import 'genscience.scss';

.introLogoDiv {
	display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.introLogo {
	width: 30%;
	transition:all .40s ease;
}

#skip-button {
    display: none;
}
