.mobile-navigation {
    .mobile-navigation__buttons-wrapper {
        width: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    #menu-main-menu {
        li {
            a {
                &:hover,
                &:focus {
                    color:#fff;
                    border-color:#fff;
                    &:before {
                        background: url(../../assets/img/icon__chevron-down--white.svg) no-repeat 50%;
                        background-size: contain;
                    }
                }
            }
            ul.sub-menu {
                display:none;
                border: 1px solid #fff;
                border-top: 0;
                border-bottom-left-radius: 1.5rem;
                border-bottom-right-radius: 1.5rem;
                padding-bottom:16px;
                li {
                    list-style:none;
                    a {
                        padding-left: 3rem;
                        border:none;
                        text-align:left;
                    }
                }
            }
            &.open-sub-menu {
                & > a  {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    &:before {
                        background: url(../../assets/img/icon__chevron-down--white.svg) no-repeat 50%;
                        background-size: contain;
                        transform: rotate(0deg);
                    }
                }
                ul.sub-menu {
                    display:block;
                }
            }
        }
    }
}


.hero-section {
    .mobile-navigation {
        &__menu-wrapper {
            .mobile-navigation__buttons-wrapper {
                form {
                    &.search-form {
                        input[type="search"] {
                            color:#fff;
                            @media (min-width:$laptop) {
                                color:$bluegray;
                            }
                        }
                    }
                }
            }
        }
    }
}