.genscience-styled-block-list {
	display:block;
    margin-left:auto;
    margin-right:auto;
    @media (min-width:$laptop) {
		display:flex;
	    align-items: flex-start;
	    justify-content: flex-start;
	    column-gap: 20px;
    }
	li {
	    background: url(../../assets/img/genscience/genscience_box_01.png) no-repeat center center;
	    background-size:cover;
	    color: #fff;
	    border-radius: 15px;
	    padding: 50px 35px;
	    margin: 30px auto 50px !important;
	    line-height: 1.35;
	    font-size: 16px;
	    font-weight: 600;
	    text-align: center;
    	display:block;
    	width:100%;
	    position:relative;
	    @media (min-width:$tablet) {
	    	width:50%;
	    }
	    @media (min-width:$laptop) {
		    flex: 1 1 0; 
		    width: 0;
		    align-self: stretch;
		    margin: 30px 0 !important;
	    }
		&:before {
			content:"" !important;
			width:60px !important;
			height:60px;
			position:absolute;
			top:0;
			left:50%;
			transform:translate(-15%,-50%);
		    background: #01445a url(../../assets/img/genscience/Genscience_Icon_magnifier.svg) center center no-repeat;
		    background-size:cover;
		    border-radius:50%;
		}
		&:nth-child(2) {
		    background: url(../../assets/img/genscience/genscience_box_02.png) no-repeat center center;
		    background-size:cover;
			&:before {
			    background: #033e63 url(../../assets/img/genscience/Genscience_Icon_People.svg) center center no-repeat;
			    background-size:cover;
			}
		}
		&:nth-child(3) {
		    background: url(../../assets/img/genscience/genscience_box_03.png) no-repeat center center;
		    background-size:cover;
			&:before {
			    background: #07355f url(../../assets/img/genscience/Genscience_Icon_Beaker.svg) center center no-repeat;
			    background-size:cover;
			}
		}
		&:nth-child(4) {
		    background: url(../../assets/img/genscience/genscience_box_04.png) no-repeat center center;
		    background-size:cover;
			&:before {
			    background: #012457 url(../../assets/img/genscience/Genscience_Icon_Target.svg) center center no-repeat;
			    background-size:cover;
			}
		}
		&:nth-child(5) {
		    background: url(../../assets/img/genscience/genscience_box_05.png) no-repeat center center;
		    background-size:cover;
			&:before {
			    background: #061940 url(../../assets/img/genscience/Genscience_Icon_DNA.svg) center center no-repeat;
			    background-size:cover;
			}
		}
	}
}

hr.wp-block-separator {
	background:#cccccc;
	border:none;
}

.genscience-promo-block {
	@media (min-width:$laptop) {
		padding-right:50%;
	    background: url(../../assets/img/genscience/about-us-genscience-image.png) top right no-repeat;
	    background-size: auto 100%;
	}
}