.main-content {

  .wp-block-file__button, .wp-block-button__link {
    background-color: transparent;
    border: 1px solid #1e7f98;
    border-radius: 3rem;
    border-top-left-radius: 0;
    font-weight: 400;
    font-family: nimbus-sans-extended, nimbus-sans, Barlow, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    position: relative;
    padding: 0.75rem 3rem 0.75rem 2rem;
    min-width: 9.375rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 1rem !important;
    letter-spacing: 0.1em;
    font-size: 0.75rem;

    &:before,
    &:after {
      content: '';
      position: absolute;
      right: 1.875rem;
      width: 0.55rem;
      height: 0.55rem;
      top: 50%;
      transform: translateY(-50%) rotate(-90deg);
    }

    &:before {
      background: url('../../assets/img/icon__chevron-down-small--white.svg') no-repeat 50%;
      background-size: contain;
      opacity: 0;
      z-index: -1;
    }

    &:after {
      background: url('../../assets/img/icon__chevron-down-small--darkblue.svg') no-repeat 50%;
      background-size: contain;
      opacity: 1;
      z-index: 1;
    }

    &:hover {
      background: #51c0dc;
      border-color: #51c0dc;
      box-shadow: none;

      &:before {
        z-index: 1;
        opacity: 1;
      }

      &:after {
        opacity: 0;
        z-index: -1;
      }
    }
  }
}
