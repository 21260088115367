.disclaimer-accordion-header {
    font-family: nimbus-sans-extended,nimbus-sans,Barlow,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    color: #8f97ac;
    font-weight: 400;
    cursor:pointer;
    margin-bottom:10px;
    &:hover {
        color:#fff;
    }
    span {
    	margin-left:10px;
    }
}
.disclaimer-accordion-content {
	padding: 10px;
    background: rgba(255,255,255,.05);
    border-radius: 30px;
    border-top-left-radius: 0;
    margin-bottom:10px;
    display:none;
    &.open {
    	display:block;
    }
    ul {
        margin-left:20px;
    }
}