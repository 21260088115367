@mixin newsletter-form-field() {
    background: #f7f7f7;
    border: 1px solid #c4c4c4;
    border-radius: 0;
    box-shadow: none;
    color: #787878;
    width:100%;
    margin-bottom: 25px;
}

@mixin newsletter-form-button() {
    background: url('../../assets/img/icon__chevron-right-small--darkblue.webp') no-repeat 80% 50%;
    background-size: 13px auto;
    border: 1px solid #1e7f98;
    border-radius: 3rem;
    border-top-left-radius: 0;
    font-weight: 400;
    font-family: nimbus-sans-extended, nimbus-sans, Barlow, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    position: relative;
    padding: 0.75rem 3rem 0.75rem 2rem;
    min-width: 9.375rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 1rem !important;
    letter-spacing: 0.1em;
    font-size: 0.75rem;
    &:hover {
        background: #1e7f98 url('../../assets/img/icon__chevron-right-small--white.webp') no-repeat 80% 50%;
        background-size: 13px auto;
        color:#fff;
    }
}

@mixin newsletter-form-label() {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    display:block;
    margin-bottom: 10px;
}