/** fonts **/
/** breakpoints **/
/** colors **/
@media (min-width: 1700px) {
  #app {
    max-width: 2000px;
  }
}

@media (min-width: 1700px) {
  .results-wrapper #st-results-container .st-result {
    flex-basis: calc(23% - 1.25rem);
  }
}
figure.wp-block-embed-vimeo .wp-block-embed__wrapper {
  padding: 56.25% 0 0 0;
  position: relative;
}

figure.wp-block-embed-vimeo iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.facet label {
  width: 100%;
  clear: both;
}

.disclaimer-accordion-header {
  font-family: nimbus-sans-extended, nimbus-sans, Barlow, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  color: #8f97ac;
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 10px;
}
.disclaimer-accordion-header:hover {
  color: #fff;
}
.disclaimer-accordion-header span {
  margin-left: 10px;
}

.disclaimer-accordion-content {
  padding: 10px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 30px;
  border-top-left-radius: 0;
  margin-bottom: 10px;
  display: none;
}
.disclaimer-accordion-content.open {
  display: block;
}
.disclaimer-accordion-content ul {
  margin-left: 20px;
}

.mobile-navigation .mobile-navigation__buttons-wrapper {
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.mobile-navigation #menu-main-menu li a:hover, .mobile-navigation #menu-main-menu li a:focus {
  color: #fff;
  border-color: #fff;
}
.mobile-navigation #menu-main-menu li a:hover:before, .mobile-navigation #menu-main-menu li a:focus:before {
  background: url(../../assets/img/icon__chevron-down--white.svg) no-repeat 50%;
  background-size: contain;
}
.mobile-navigation #menu-main-menu li ul.sub-menu {
  display: none;
  border: 1px solid #fff;
  border-top: 0;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  padding-bottom: 16px;
}
.mobile-navigation #menu-main-menu li ul.sub-menu li {
  list-style: none;
}
.mobile-navigation #menu-main-menu li ul.sub-menu li a {
  padding-left: 3rem;
  border: none;
  text-align: left;
}
.mobile-navigation #menu-main-menu li.open-sub-menu > a {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.mobile-navigation #menu-main-menu li.open-sub-menu > a:before {
  background: url(../../assets/img/icon__chevron-down--white.svg) no-repeat 50%;
  background-size: contain;
  transform: rotate(0deg);
}
.mobile-navigation #menu-main-menu li.open-sub-menu ul.sub-menu {
  display: block;
}

.hero-section .mobile-navigation__menu-wrapper .mobile-navigation__buttons-wrapper form.search-form input[type=search] {
  color: #fff;
}
@media (min-width: 1024px) {
  .hero-section .mobile-navigation__menu-wrapper .mobile-navigation__buttons-wrapper form.search-form input[type=search] {
    color: #8f97ac;
  }
}

form#newsletter-subscribe textarea {
  background: #f7f7f7;
  border: 1px solid #c4c4c4;
  border-radius: 0;
  box-shadow: none;
  color: #787878;
  width: 100%;
  margin-bottom: 25px;
}
form#newsletter-subscribe input[type=checkbox] {
  margin-right: 10px;
}
form#newsletter-subscribe input:not([type=button]):not([type=checkbox]):not([type=submit]) {
  background: #f7f7f7;
  border: 1px solid #c4c4c4;
  border-radius: 0;
  box-shadow: none;
  color: #787878;
  width: 100%;
  margin-bottom: 25px;
}
form#newsletter-subscribe input.error {
  border: 1px solid red !important;
}
form#newsletter-subscribe input[type=submit] {
  background: url("../../assets/img/icon__chevron-right-small--darkblue.webp") no-repeat 80% 50%;
  background-size: 13px auto;
  border: 1px solid #1e7f98;
  border-radius: 3rem;
  border-top-left-radius: 0;
  font-weight: 400;
  font-family: nimbus-sans-extended, nimbus-sans, Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  padding: 0.75rem 3rem 0.75rem 2rem;
  min-width: 9.375rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 1rem !important;
  letter-spacing: 0.1em;
  font-size: 0.75rem;
}
form#newsletter-subscribe input[type=submit]:hover {
  background: #1e7f98 url("../../assets/img/icon__chevron-right-small--white.webp") no-repeat 80% 50%;
  background-size: 13px auto;
  color: #fff;
}
form#newsletter-subscribe label {
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  display: block;
  margin-bottom: 10px;
}
form#newsletter-subscribe label[for=disclaimer] {
  position: relative;
  margin-bottom: 50px;
  padding-bottom: 8px;
}
form#newsletter-subscribe label.error {
  color: red;
  font-weight: 400;
  font-size: 14px;
  margin-top: -15px;
}
form#newsletter-subscribe label.error#disclaimer-error {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(0, 100%);
  margin: 0;
}

.research-collaborations-grid {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.35;
  text-align: center;
  color: #292d4a;
  border: 2px solid #0485b5;
  border-right: 0;
  border-bottom: 0;
  border-radius: 3px;
  overflow: hidden;
}
.research-collaborations-grid .col {
  border-bottom: 2px solid #0485b5;
  border-right: 2px solid #0485b5;
  justify-content: center;
  align-items: center;
}
.research-collaborations-grid .col .list-content {
  padding: 20px 0;
  width: 100%;
}
.research-collaborations-grid .col .list-content .item-thumbnail {
  height: 80px;
  width: 100%;
  margin: 5px auto 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 110% auto;
  z-index: -1;
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1024px) {
  .research-collaborations-grid .col .list-content .item-thumbnail {
    background-size: 150% auto;
  }
}

.modal-container .merged-with-subhead {
  margin-bottom: 20px;
}

.main-content .wp-block-file__button, .main-content .wp-block-button__link {
  background-color: transparent;
  border: 1px solid #1e7f98;
  border-radius: 3rem;
  border-top-left-radius: 0;
  font-weight: 400;
  font-family: nimbus-sans-extended, nimbus-sans, Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  padding: 0.75rem 3rem 0.75rem 2rem;
  min-width: 9.375rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 1rem !important;
  letter-spacing: 0.1em;
  font-size: 0.75rem;
}
.main-content .wp-block-file__button:before, .main-content .wp-block-file__button:after, .main-content .wp-block-button__link:before, .main-content .wp-block-button__link:after {
  content: "";
  position: absolute;
  right: 1.875rem;
  width: 0.55rem;
  height: 0.55rem;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
}
.main-content .wp-block-file__button:before, .main-content .wp-block-button__link:before {
  background: url("../../assets/img/icon__chevron-down-small--white.svg") no-repeat 50%;
  background-size: contain;
  opacity: 0;
  z-index: -1;
}
.main-content .wp-block-file__button:after, .main-content .wp-block-button__link:after {
  background: url("../../assets/img/icon__chevron-down-small--darkblue.svg") no-repeat 50%;
  background-size: contain;
  opacity: 1;
  z-index: 1;
}
.main-content .wp-block-file__button:hover, .main-content .wp-block-button__link:hover {
  background: #51c0dc;
  border-color: #51c0dc;
  box-shadow: none;
}
.main-content .wp-block-file__button:hover:before, .main-content .wp-block-button__link:hover:before {
  z-index: 1;
  opacity: 1;
}
.main-content .wp-block-file__button:hover:after, .main-content .wp-block-button__link:hover:after {
  opacity: 0;
  z-index: -1;
}

.genscience-styled-block-list {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1024px) {
  .genscience-styled-block-list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    column-gap: 20px;
  }
}
.genscience-styled-block-list li {
  background: url(../../assets/img/genscience/genscience_box_01.png) no-repeat center center;
  background-size: cover;
  color: #fff;
  border-radius: 15px;
  padding: 50px 35px;
  margin: 30px auto 50px !important;
  line-height: 1.35;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  display: block;
  width: 100%;
  position: relative;
}
@media (min-width: 768px) {
  .genscience-styled-block-list li {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .genscience-styled-block-list li {
    flex: 1 1 0;
    width: 0;
    align-self: stretch;
    margin: 30px 0 !important;
  }
}
.genscience-styled-block-list li:before {
  content: "" !important;
  width: 60px !important;
  height: 60px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-15%, -50%);
  background: #01445a url(../../assets/img/genscience/Genscience_Icon_magnifier.svg) center center no-repeat;
  background-size: cover;
  border-radius: 50%;
}
.genscience-styled-block-list li:nth-child(2) {
  background: url(../../assets/img/genscience/genscience_box_02.png) no-repeat center center;
  background-size: cover;
}
.genscience-styled-block-list li:nth-child(2):before {
  background: #033e63 url(../../assets/img/genscience/Genscience_Icon_People.svg) center center no-repeat;
  background-size: cover;
}
.genscience-styled-block-list li:nth-child(3) {
  background: url(../../assets/img/genscience/genscience_box_03.png) no-repeat center center;
  background-size: cover;
}
.genscience-styled-block-list li:nth-child(3):before {
  background: #07355f url(../../assets/img/genscience/Genscience_Icon_Beaker.svg) center center no-repeat;
  background-size: cover;
}
.genscience-styled-block-list li:nth-child(4) {
  background: url(../../assets/img/genscience/genscience_box_04.png) no-repeat center center;
  background-size: cover;
}
.genscience-styled-block-list li:nth-child(4):before {
  background: #012457 url(../../assets/img/genscience/Genscience_Icon_Target.svg) center center no-repeat;
  background-size: cover;
}
.genscience-styled-block-list li:nth-child(5) {
  background: url(../../assets/img/genscience/genscience_box_05.png) no-repeat center center;
  background-size: cover;
}
.genscience-styled-block-list li:nth-child(5):before {
  background: #061940 url(../../assets/img/genscience/Genscience_Icon_DNA.svg) center center no-repeat;
  background-size: cover;
}

hr.wp-block-separator {
  background: #cccccc;
  border: none;
}

@media (min-width: 1024px) {
  .genscience-promo-block {
    padding-right: 50%;
    background: url(../../assets/img/genscience/about-us-genscience-image.png) top right no-repeat;
    background-size: auto 100%;
  }
}

.introLogoDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.introLogo {
  width: 30%;
  transition: all 0.4s ease;
}

#skip-button {
  display: none;
}