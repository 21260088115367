form#newsletter-subscribe {
    textarea {
        @include newsletter-form-field();
    }
    input {
        &[type=checkbox] {
            margin-right:10px;
        }
        &:not([type=button]):not([type=checkbox]):not([type=submit]) {
            @include newsletter-form-field();
        }
        &.error {
            border: 1px solid red !important;
        }
        &[type="submit"] {
            @include newsletter-form-button();
        }
    }
    label {
        @include newsletter-form-label();
        &[for=disclaimer] {
            position: relative;
            margin-bottom: 50px;
            padding-bottom: 8px;
        
        }
        &.error {
            color: red;
            font-weight: 400;
            font-size: 14px;
            margin-top: -15px;
            &#disclaimer-error {
                position: absolute;
                bottom: 0;
                left: 0;
                transform: translate(0,100%);
                margin:0;
            }
        }   
    }
}