.research-collaborations-grid {
  font-size: 13px;
  font-weight:500;
  line-height:1.35;
  text-align: center;
  color:$mediumblue;
  border:2px solid $midblue;
  border-right:0;
  border-bottom:0;
  border-radius:3px;
  overflow:hidden;
  .col {
    border-bottom:2px solid $midblue;
    border-right:2px solid $midblue;
    justify-content:center;
    align-items:center;
    .list-content {
      padding: 20px 0;
      width:100%;
      .item-thumbnail {
        height:80px;
        width: 100%;
        margin:5px auto 0;
        background-repeat: no-repeat;
        background-position: center center;
        background-size:110% auto;
        z-index: -1;
        font-size:0; //hide the text since its only for screen readers
        display:flex;
        align-items:center;
        justify-content:center;
        @media (min-width:$laptop) {
          background-size:150% auto;          
        }
      }
    }
  }
}
